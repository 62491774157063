// 角色表单显示类型
export const ShowTypeEnum = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

// 显示类型文案枚举
export const ShowTypeTextEnum = {
  ADD: '添加角色',
  EDIT: '编辑橘色',
};

// 起禁用选项
export const enabledDisabledOptions = [
  {
    id: '1',
    value: '启用',
  },
  {
    id: '0',
    value: '禁用',
  },
];

// 操作信息类型选项
export const operationInfoTypeConfig = {
  authInfo: {
    key: 'authInfo',
    label: '功能权限',
  },
  projectInfo: {
    key: 'projectInfo',
    label: '项目数据权限',
  },
};
