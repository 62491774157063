import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

//获取平台列表
export const getPlatformList = () => {
  return fetchApi(URL.GET_PLATFORM_LIST);
}

//登录
export const login = (data) => {
  return fetchApi(URL.LOGIN, data, "post");
}

/**
  * @description: 获取平台列表--V2
  * @author: cxd
  * @time: 2024/5/28
 **/
export const getPlatformListV2 = () => {
  return fetchApi('/api/dscloud-authority-center/userRole/findRolePlatform');
}
