import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import {getLocalStorage} from "@/utils/storage"

//获取角色列表
export const getRoleList = (searchData, pageNum, pageSize) => {
  const data = {
    ...searchData,
    pageNum,
    pageSize
  }
  return fetchApi(URL.GET_ROLE_LIST, data, "post", "data");
}

//获取商户列表
export const getMerchantList = () => {
  return fetchApi('/api/dscloud-authority-center/userRole/tenant-list');
}

//获取当前人在当前菜单可查询项目
export const getProjectList = menuId => {
  const data = {
    menuId
  }
  return fetchApi(URL.GET_CURRENT_ROLE_PROJECT_LIST, data);
}

//添加角色
export const addRole = data => {
  const submitData = {
    ...data,
    tenantId: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.ADD_ROLE, submitData, "post", "data");
}

//获取角色详情
export const getRoleDetail = roleId => {
  const data = {
    roleId
  }
  return fetchApi(URL.GET_ROLE_DETAIL, data);
}

//角色启禁用
export const roleDisable = (roleId, status) => {
  const data = {
    roleId,
    status,
  }
  return fetchApi(URL.ROLE_DISABLE, data);
}

//删除角色
export const deleteRole = roleId => {
  const data = {
    roleId
  }
  return fetchApi(URL.DELETE_ROLE, data);
}

//编辑角色
export const editRole = data => {
  return fetchApi(URL.EDIT_ROLE, data, "post", "data");
}

//获取当前租户在当前项目下的数可选择菜单
export const getCurrentLesseeInCurrentPlatformShowMenuList = platformId => {
  const submitData = {
    platformId,
  }
  return fetchApi(URL.GET_CURRENT_LESSEE_IN_CURRENT_PLATFORM_SHOW_MENU_LIST, submitData, 'post', 'data');
}

//组织架构-树形结构
export const getOrganizationTree = (tenantId) => {
  const submitData = {
    showSupperNodeStatus:false,
    tenantId,
  }
  return fetchApi(URL.ORGANIZATION_ORGANIZATIONTREE, submitData, "post", "body");
}