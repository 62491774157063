<template>
  <div class="addRole">
    <ds-header :title="`角色管理/${ShowTypeTextEnum[showType]}`"></ds-header>
    <div class="content">
      <div class="leftDiv">
        <a-form-model :rules="rules" :model="form" ref="ruleForm">
          <a-form-model-item label="角色名称" prop="roleName">
            <a-input v-model="form.roleName" placeholder="请输入角色名称" :disabled="disabled" />
          </a-form-model-item>
          <a-form-model-item label="角色描述" prop="remark">
            <a-textarea v-model="form.remark" placeholder="请输入角色描述" :row="10" style="height: 100px"
              :disabled="disabled" />
          </a-form-model-item>
          <a-form-model-item label="状态" prop="status">
            <a-select v-model="form.status" placeholder="请选择"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode" :disabled="disabled">
              <a-select-option v-for="option in options" :key="option.id" :value="option.id">
                {{ option.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="权限标识">
            <a-input v-model="form.roleKey" placeholder="请输入权限标识" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="rightDiv">
        <div class="div-menu">
          <a-menu style="width: 100%" mode="vertical" :selectedKeys="[selectedPlatformId]">
            <a-menu-item v-for="item in platformOption" :key="item.platformId" @click="handleClick(item)">
              {{ item.platformName }}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="divContent">
          <div>
            <a-menu v-model="current" mode="horizontal">
              <a-menu-item :key="operationInfoTypeConfig.authInfo.key"> {{ operationInfoTypeConfig.authInfo.label }}
              </a-menu-item>
              <a-menu-item :key="operationInfoTypeConfig.projectInfo.key"> {{ operationInfoTypeConfig.projectInfo.label
                }}
              </a-menu-item>
            </a-menu>
          </div>
          <div class="select-all" v-if="current[0] === operationInfoTypeConfig.authInfo.key">
            <a-checkbox @change="selectedAll" v-model="isSelectedAll"> 全选 </a-checkbox>
          </div>
          <div v-show="current[0] === operationInfoTypeConfig.authInfo.key">
            <a-tree :checkedKeys="currentSelectedMenuForSelectedPlatform" checkable :tree-data="treeData"
              @check="onCheck" :replaceFields="replaceFields" />
          </div>
          <div v-show="current[0] === operationInfoTypeConfig.projectInfo.key">
            <a-table :rowKey="(record, index) => record.id"
              :row-selection="{ selectedRowKeys: form.projectIds, onChange: onSelectChange }" :columns="columns"
              :data-source="projectList" />
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <ds-button :type="$DSBT.DS_SAVE" @click="save" />
      <ds-button :type="$DSBT.DS_BACK" @click="goBack" style="margin-left: 20px" />
    </div>
  </div>
</template>

<script>
import { getPlatformListV2 } from '@/api/common';
import { getMenuList } from '@/api/system/menuManage';
import { getCurrentUserProjectList } from '@/api/baseSet/projectManage';
import { REQUEST_CODE } from '@/constant/status';
import * as api from '@/api/authManage/roleManage';
import { mapState } from 'vuex';
import { isPlatformAccount } from '@/config';
import debounce from 'lodash/debounce';
import { ShowTypeEnum, ShowTypeTextEnum, enabledDisabledOptions, operationInfoTypeConfig } from '../help'

export default {
  name: 'AddRole',
  components: {},
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
    disabled() {
      return this.showType === ShowTypeEnum.EDIT;
    }
  },
  data() {
    return {
      ShowTypeEnum,
      ShowTypeTextEnum,
      operationInfoTypeConfig,
      // 是否是平台账号
      isPlatformAccount: isPlatformAccount,
      // 显示状态
      showType: '',
      // 角色id
      roleId: '',
      form: {
        // 角色名称
        roleName: '',
        // 角色描述
        remark: '',
        // 角色状态
        status: undefined,
        // 菜单ids
        menuIds: [],
        // 项目ids
        projectIds: [],
        // 权限标识
        roleKey: '',
        // 显示菜单ids
        showMenuIds: []
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { max: 20, message: '角色名称最大20位', trigger: 'blur' },
        ],
        status: [{ required: true, message: '请设置角色状态', trigger: 'blur' }],
        remark: [{ max: 50, message: '角色描述最大50位', trigger: 'blur' }],
      },
      // 状态选项
      options: enabledDisabledOptions,
      // 平台选项
      platformOption: [],
      // 选中平台id
      selectedPlatformId: '',
      // 选中平台key
      selectedPlatformKey: '',
      // 当前选择平台选中菜单
      currentSelectedMenuForSelectedPlatform: {},
      // 是否是历史数据
      historyDataState: false,

      // tree数据
      treeData: [],

      // 当前选项
      current: [operationInfoTypeConfig.authInfo.key],
      // 项目数据
      columns: [
        {
          title: '全选',
          dataIndex: 'project_name',
        },
      ],
      // 项目列表
      projectList: [],
      // 所有菜单
      allMenu: [],
      // 是否选中所有菜单
      isSelectedAll: false,
      replaceFields: {
        children: 'children',
        title: 'menuName',
        key: 'menuId',
      }
    };
  },
  methods: {
    //保存
    save: debounce(function () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.form.projectIds.length) {
            this.$message.error('请选择项目数据权限');
            reutnr;
          }
          if (this.showType === ShowTypeEnum.EDIT) {
            this.editRole();
          } else {
            this.addRole();
          }
        } else {
        }
      });
    }, 500),

    //返回
    goBack() {
      this.$router.back();
    },

    //选择平台
    handleClick(e) {
      this.selectedPlatformId = e.platformId;
      this.selectedPlatformKey = e.platformKey;
      this.getCurrentLesseeInCurrentPlatformShowMenuList();
    },

    // 选中菜单
    onCheck(checkedKeys, e) {
      // 向展示菜单id数组进行赋值操作
      this.currentSelectedMenuForSelectedPlatform = {
        checked: checkedKeys,
        halfChecked: e.halfCheckedKeys
      }

      // 判断选择菜单后在当前平台下是否是全部选中
      this.isSelectedAll = this.judgeIsAllSelect(this.currentSelectedMenuForSelectedPlatform.checked.concat(this.currentSelectedMenuForSelectedPlatform.halfChecked));
      // 更新菜单数据
      this.updateSubmitFormShowMenuIdsData(this.currentSelectedMenuForSelectedPlatform);
    },

    // 选择项目
    onSelectChange(selectedRowKeys) {
      this.form.projectIds = selectedRowKeys;
    },

    // 菜单权限全选
    selectedAll(event) {
      this.isSelectedAll = event.target.checked;
      if (this.isSelectedAll) {
        this.currentSelectedMenuForSelectedPlatform = {
          checked: this.allMenu,
          halfChecked: [],
        }
      } else {
        this.currentSelectedMenuForSelectedPlatform = {
          checked: [],
          halfChecked: [],
        }
      }
      // 更新菜单数据
      this.updateSubmitFormShowMenuIdsData(this.currentSelectedMenuForSelectedPlatform);
    },

    // 判断是否是全选
    judgeIsAllSelect(currentData) {
      let isAll = true;
      if (this.allMenu.length) {
        this.allMenu.forEach((item) => {
          if (currentData.indexOf(item) === -1) {
            isAll = false;
          }
        });
      } else {
        isAll = false;
      }

      return isAll;
    },

    /**网络请求 */
    // 获取平台列表
    async getPlatform() {
      try {
        const result = await getPlatformListV2();
        if (result.code === REQUEST_CODE) {
          this.platformOption = result.data;
          this.selectedPlatformId = this.platformOption[0].platformId;
          this.selectedPlatformKey = this.platformOption[0].platformKey;
          this.showMenuIdsDataDispose(this.platformOption, true);
          await this.getCurrentLesseeInCurrentPlatformShowMenuList();
          if (this.showType === ShowTypeEnum.EDIT) {
            this.getRoleDetail();
          }
          this.getProject();
        }
      } catch (error) { }
    },
    // 获取菜单列表
    async getCurrentLesseeInCurrentPlatformShowMenuList() {
      return new Promise(async (resolve, reject) => {
        try {
          if (isPlatformAccount) {
            const result = await getMenuList(this.selectedPlatformId, 1, 999);
            if (result.code === REQUEST_CODE) {
              this.treeData = result.data.menuList;
              this.allMenu = [];
              this.selectedCurrentPlatFormAllMenu(this.treeData);
              this.showMenuIdsDataDispose();
              this.isSelectedAll = this.judgeIsAllSelect(this.currentSelectedMenuForSelectedPlatform.checked.concat(this.currentSelectedMenuForSelectedPlatform.halfChecked));
              resolve(true);
            }
          } else {
            const result = await api.getCurrentLesseeInCurrentPlatformShowMenuList(this.selectedPlatformId);
            const { code, data: { menuList } } = result
            if (code === REQUEST_CODE) {
              this.treeData = menuList;
              this.allMenu = [];
              this.selectedCurrentPlatFormAllMenu(this.treeData);
              this.showMenuIdsDataDispose();
              this.isSelectedAll = this.judgeIsAllSelect(this.currentSelectedMenuForSelectedPlatform.checked.concat(this.currentSelectedMenuForSelectedPlatform.halfChecked));
              resolve(true);
            }
          }
        } catch (error) {
          reject(false)
        }
      })
    },
    //获取项目列表
    async getProject() {
      try {
        const searchForm = {
          project_name: '',
          project_address: '',
          penetrate: '',
        };
        const result = await getCurrentUserProjectList(searchForm, 1, 999);
        if (result.code === REQUEST_CODE) {
          this.projectList = result.data.projects;
        } else {
          this.$message.error('获取项目列表失败');
        }
      } catch (error) { }
    },
    //添加角色
    async addRole() {
      let menuIds = [];
      this.form.showMenuIds.forEach(item => {
        menuIds = menuIds.concat(item.checked);
        menuIds = menuIds.concat(item.halfChecked);
      });
      const form = { ...this.form, menuIds };
      if (!form.menuIds.length) {
        this.$message.error('请选择功能权限');
        return;
      }
      try {
        const result = await api.addRole(form);
        if (result.code === REQUEST_CODE) {
          this.$router.go(-1);
          this.$message.success(result.msg);
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error('添加失败');
      }
    },
    //获取角色详情
    async getRoleDetail() {
      try {
        const result = await api.getRoleDetail(this.roleId);
        if (result.code === REQUEST_CODE) {
          const { data } = result;

          // 下面逻辑是处理老数据showMenuIds为null的处理
          const showMenuIds = this.historyDataInitDispose(data.showMenuIds, data.menuIds);
          if (!data.showMenuIds) {
            this.historyDataState = true;
          }

          this.form = {
            roleName: data.roleName,
            remark: data.remark,
            status: data.status,
            menuIds: [],
            tenantId: data.tenantId,
            projectIds: data.projectIds ? data.projectIds : [],
            roleId: data.roleId,
            roleKey: data.roleKey,
            showMenuIds
          };
          this.showMenuIdsDataDispose();
          this.isSelectedAll = this.judgeIsAllSelect(this.currentSelectedMenuForSelectedPlatform.checked.concat(this.currentSelectedMenuForSelectedPlatform.halfChecked));
        }
      } catch (error) {
        console.log(error);
        this.$message.error('获取角色信息失败');
      }
    },
    //编辑角色
    async editRole() {
      let menuIds = [];
      this.form.showMenuIds.forEach(item => {
        menuIds = menuIds.concat(item.checked);
        menuIds = menuIds.concat(item.halfChecked);
      });
      const form = { ...this.form, menuIds };
      if (!form.menuIds.length) {
        this.$message.error('请选择功能权限');
        return;
      }
      try {
        const result = await api.editRole(form);
        if (result.code === REQUEST_CODE) {
          this.$router.back();
          this.$message.success(result.msg);
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error('修改失败');
      }
    },
    /**数据处理 */
    // 显示菜单集合
    showMenuIdsDataDispose(platformOption, init = false) {
      if (init) {
        platformOption.forEach(item => {
          this.form.showMenuIds.push({
            checked: [],
            halfChecked: [],
            platformKey: item.platformKey
          })
        });
        this.currentSelectedMenuForSelectedPlatform = {
          checked: [],
          halfChecked: [],
        }
      } else {
        this.form.showMenuIds.forEach(item => {
          if (item.platformKey === this.selectedPlatformKey) {
            this.currentSelectedMenuForSelectedPlatform = {
              checked: item.checked,
              halfChecked: item.halfChecked
            }
          }
        });
        // 如果是历史数据 要对数据进行处理, 要剔除掉不属于当前平台的菜单id
        if (this.historyDataState) {
          const tempChecked = [];
          this.allMenu.forEach(item => {
            if (this.currentSelectedMenuForSelectedPlatform.checked && this.currentSelectedMenuForSelectedPlatform.checked.includes(item)) {
              tempChecked.push(item);
            }
          });
          
          this.currentSelectedMenuForSelectedPlatform = {
            ...this.currentSelectedMenuForSelectedPlatform,
            checked: tempChecked
          };
          // 更新菜单数据
          this.updateSubmitFormShowMenuIdsData(this.currentSelectedMenuForSelectedPlatform);
        }
      }

    },
    // 更新提交表单显示菜单数据数据
    updateSubmitFormShowMenuIdsData(currentSelectedMenu) {
      const showMenuIds = []
      this.form.showMenuIds.forEach(item => {
        if (item.platformKey === this.selectedPlatformKey) {
          showMenuIds.push({
            platformKey: item.platformKey,
            checked: currentSelectedMenu.checked,
            halfChecked: currentSelectedMenu.halfChecked,
          })
        } else {
          showMenuIds.push(item);
        }
      });
      this.form.showMenuIds = showMenuIds;
    },
    // 选中当前平台所有菜单
    selectedCurrentPlatFormAllMenu(menuList) {
      menuList.forEach((item) => {
        if (item.children && item.children.length) {
          this.allMenu.push(item.menuId);
          this.selectedCurrentPlatFormAllMenu(item.children);
        } else {
          this.allMenu.push(item.menuId);
        }
      });
    },
    // 历史数据初始化处理 
    historyDataInitDispose(showMenuIds, menuIds) {
      let tempShowMenuIds = showMenuIds ? showMenuIds : [];
      if (!showMenuIds) {
        this.platformOption.forEach(item => {
          tempShowMenuIds.push({
            checked: menuIds,
            halfChecked: [],
            platformKey: item.platformKey
          });
        });
      }
      return tempShowMenuIds
    }
  },
  created() {
    this.showType = this.$route.query.showType;
    this.roleId = this.$route.query.roleId;
    this.getPlatform();
  },
};
</script>

<style lang="scss" scoped>
.addRole {
  .content {
    overflow: hidden;

    .leftDiv {
      float: left;
      width: 25%;
      height: 100%;
    }

    .rightDiv {
      float: right;
      width: 70%;
      height: 100%;
    }
  }
}

.div-menu {
  width: 25%;
  height: 100%;
  float: left;

  .elCol {
    width: 100%;
    text-align: center;

    .elMenu {
      background: rgb(247, 248, 252);
      height: 100%;

      .elMenuItem {
        span {
          width: 100%;
        }
      }
    }
  }
}

.divContent {
  width: 75%;
  float: right;
  height: 100%;
  overflow: auto;
}

::v-deep .ant-menu-vertical .ant-menu-item {
  // font-size: 20px;
  color: #000;
  font-weight: 700;
}

.select-all {
  padding-left: 26px;
  padding-top: 5px;
}
</style>
